<section *ngIf="!getOnlyForm; else onlyFormSection" id="hero">
  <div class="container col-xl-12 px-4 pt-5">
    <div class="row align-items-center g-lg-5 py-5">
      <ng-container *ngIf="typeDealPage !== DealEntitiesEnum.City; else listQuotes">
        <div class="col-md-12 col-lg-5 col-xl-5 col-xxl-6 text-lg-start">
          <h1 class="display-4 fw-bold lh-1 mb-3">Cheap Business Class Tickets</h1>
          <h2 class="col-lg-12">Get a quote now and save up to 20-50%</h2>

          <div class="row benefits">
            <div class="col-4 col-lg-4 text-lg-start">
              <img
                width="64px"
                height="32px"
                class="bi"
                alt="Accredited business"
                [defaultImage]="cdnPath + '/assets/images/content/icons/accredited-background.webp'"
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Accredited.svg'" />
              <h2>Accredited business</h2>
              <p></p>
            </div>
            <div class="col-4 col-lg-4 text-lg-start">
              <img
                width="96px"
                height="30px"
                class="bi"
                alt="Trustpilot top rated"
                [defaultImage]="cdnPath + '/assets/images/content/icons/trustpilot-background.webp'"
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot.svg'" />
              <h2>Trustpilot top rated</h2>
              <p></p>
            </div>
            <div class="col-4 col-lg-4 text-lg-start">
              <img
                width="34px"
                height="34px"
                alt="Live concierge"
                class="bi"
                [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Live.svg'" />
              <h2>Live concierge</h2>
              <p></p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #listQuotes>
        <div
          class="col-md-12 col-lg-5 col-xl-5 col-xxl-6 text-lg-start form-price order-2 order-lg-1"
          (click)="openGetQuoteModal($event)">
          <div [innerHTML]="textQuotes | safeHtml"></div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="formSection"></ng-container>

      <div
        class="row col-md-12 col-lg-7 col-xl-7 col-xxl-6 flights-form order-3 offset-xxl-6 offset-xl-5 offset-lg-5 d-none d-md-flex">
        <div class="privacy-section">
          <strong>*No Spam - only phone-exclusive deals. No purchase necessary.</strong>
          By providing your contact details and clicking on "Get a Free Quote" you agree to be
          contacted for travel information via automated phone and text messages and by email. Your
          consent to receive such messages is not a condition of purchase. We respect your
          <strong class="link" routerLink="/privacy-policy">privacy</strong>.
        </div>
      </div>

      <div
        (click)="
          typeDealPage !== DealEntitiesEnum.City ? null : openGetQuoteModalWithoutParameters()
        "
        class="row row-cols-3 row-cols-sm-3 row-cols-md-6 row-cols-lg-6 order-3 g-2 justify-content-around air-logos">
        <div class="col">
          <img
            width="100%"
            height="100%"
            [defaultImage]="cdnPath + '/assets/images/content/icons/airlines-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/logos/01.svg'"
            alt="Airfrance" />
        </div>
        <div class="col">
          <img
            width="100%"
            height="100%"
            [defaultImage]="cdnPath + '/assets/images/content/icons/airlines-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/logos/02.svg'"
            alt="Emirates" />
        </div>
        <div class="col">
          <img
            width="100%"
            height="100%"
            [defaultImage]="cdnPath + '/assets/images/content/icons/airlines-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/logos/03.svg'"
            alt="Cathay Pacific" />
        </div>

        <div class="col">
          <img
            width="100%"
            height="100%"
            [defaultImage]="cdnPath + '/assets/images/content/icons/airlines-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/logos/04.svg'"
            alt="British Airways" />
        </div>
        <div class="col">
          <img
            width="100%"
            height="100%"
            [defaultImage]="cdnPath + '/assets/images/content/icons/airlines-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/logos/05.svg'"
            alt="Lufthansa" />
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #onlyFormSection>
  <ng-container *ngTemplateOutlet="formSection"></ng-container>
</ng-template>

<ng-template #formSection>
  <form
    [class.w-100]="getOnlyForm"
    [style.background]="getOnlyForm ? '#f0cc7f' : 'inherit'"
    class="col-md-12 mx-auto col-lg-7 col-xl-7 col-xxl-6 flights-form order-1">
    <div class="px-0">
      <h3 class="px-4" [style.margin-bottom]="getOnlyForm ? '0.5rem' : '0'">Get a FREE Quote</h3>
    </div>

    <div class="row m-0 px-0 select-div">
      <div class="form-floating col-4 px-0">
        <div class="box-select">
          <div
            (click)="tripTypeContent = !tripTypeContent"
            class="select-box__current tripClick"
            tabindex="0">
            <div
              class="select-box__value tripClick"
              *ngIf="queryFlightParams.tripType === 'MultiCity'">
              <input
                checked="checked"
                class="select-box__input tripClick"
                id="multi-city"
                name="way"
                type="radio"
                value="3" />
              <p class="select-box__input-text tripClick">Multi-city</p>
            </div>
            <div
              class="select-box__value tripClick"
              *ngIf="queryFlightParams.tripType === 'OneWay'">
              <input
                checked="checked"
                class="select-box__input tripClick"
                id="one-way"
                name="way"
                type="radio"
                value="2" />
              <p class="select-box__input-text tripClick">One-Way</p>
            </div>
            <div class="select-box__value tripClick" *ngIf="queryFlightParams.tripType === 'Round'">
              <input
                checked="checked"
                class="select-box__input tripClick"
                id="round-trip"
                name="way"
                type="radio"
                value="1" />
              <p class="select-box__input-text tripClick">Round-Trip</p>
            </div>
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/empty-background.png'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/chevron_down.svg'"
              alt="Arrow Icon"
              aria-hidden="true"
              [class.select-box__icon_rotate]="tripTypeContent"
              class="select-box__icon tripClick" />
          </div>
          <ul
            *ngIf="tripTypeContent"
            class="select-box__list"
            id="trip-type"
            (appClickOutside)="onClickedOutsideTripType($event)">
            <li
              id="round-trip"
              valtype="round"
              [ngClass]="{ active: queryFlightParams.tripType === 'Round' }"
              (click)="changeTripType('Round', roundTripForm); tripTypeContent = false">
              <label aria-hidden="true" class="select-box__option" for="round-trip"
                >Round-Trip</label
              >
            </li>
            <li
              id="one-way"
              valtype="oneway"
              [ngClass]="{ active: queryFlightParams.tripType === 'OneWay' }"
              (click)="changeTripType('OneWay', oneWayForm); tripTypeContent = false">
              <label aria-hidden="true" class="select-box__option" for="one-way">One-Way</label>
            </li>
            <li
              id="multi-city"
              valtype="multi"
              [ngClass]="{
                active: queryFlightParams.tripType === 'MultiCity',
              }"
              (click)="changeTripType('MultiCity', multiCityForm); tripTypeContent = false">
              <label aria-hidden="true" class="select-box__option" for="multi-city"
                >Multi-city</label
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="form-floating col-4 px-0">
        <div class="select-box">
          <div
            (click)="cabinContent = !cabinContent"
            class="select-box__current cabinClick"
            tabindex="0">
            <div class="select-box__value cabinClick">
              <input
                checked="checked"
                class="select-box__input cabinClick"
                name="Class"
                type="radio" />
              <p class="select-box__input-text cabinClick">
                {{
                  queryFlightParams.cabin === 'PREMIUMECO'
                    ? 'Premium Economy'
                    : capitalize(queryFlightParams.cabin)
                }}
                <span class="cabinClick" *ngIf="queryFlightParams.cabin !== 'PREMIUMECO'"
                  >Class
                </span>
              </p>
            </div>
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/empty-background.png'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/chevron_down.svg'"
              alt="Arrow Icon"
              aria-hidden="true"
              [class.select-box__icon_rotate]="cabinContent"
              class="select-box__icon cabinClick" />
          </div>
          <ul
            class="select-box__list"
            *ngIf="cabinContent"
            (appClickOutside)="onClickedOutsideCabinType($event)">
            <li
              [ngClass]="{ active: queryFlightParams.cabin === 'BUSINESS' }"
              (click)="changeCabinType('BUSINESS'); cabinContent = false">
              <label aria-hidden="true" class="select-box__option">Business Class</label>
            </li>
            <li
              [ngClass]="{ active: queryFlightParams.cabin === 'FIRST' }"
              (click)="changeCabinType('FIRST'); cabinContent = false">
              <label aria-hidden="true" class="select-box__option">First Class</label>
            </li>
            <li
              [ngClass]="{ active: queryFlightParams.cabin === 'PREMIUMECO' }"
              (click)="changeCabinType('PREMIUMECO'); cabinContent = false">
              <label aria-hidden="true" class="select-box__option">Premium Economy</label>
            </li>
            <li
              [ngClass]="{ active: queryFlightParams.cabin === 'ECONOMY' }"
              (click)="changeCabinType('ECONOMY'); cabinContent = false">
              <label aria-hidden="true" class="select-box__option">Economy Class</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="form-floating col-4 px-0">
        <div class="select-box">
          <div
            class="select-box__current passengersClick"
            tabindex="0"
            (click)="passengersContent = !passengersContent">
            <div
              class="select-box__value passengersClick"
              *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
              <input
                class="select-box__input passengersClick"
                type="radio"
                value="{{ count }}"
                name="modal-Ben"
                checked="checked" />
              <p class="select-box__input-text passengersClick">
                {{ Number(queryFlightParams.passengers) }}
                {{ Number(queryFlightParams.passengers) > 1 ? 'Travelers' : 'Traveler' }}
              </p>
            </div>
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/empty-background.png'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/chevron_down.svg'"
              alt="Arrow Icon"
              aria-hidden="true"
              [class.select-box__icon_rotate]="passengersContent"
              class="select-box__icon passengersClick" />
          </div>
          <ul
            *ngIf="passengersContent"
            class="select-box__list"
            (appClickOutside)="onClickedOutsidePassengers($event)">
            <li
              *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              name="a1"
              [ngClass]="{ active: Number(queryFlightParams.passengers) === Number(count) }"
              (click)="
                queryFlightParams.passengers = count; changeTravelers(); passengersContent = false
              ">
              <label aria-hidden="true" class="select-box__option">
                {{ count }} {{ count > 1 ? 'Travelers' : 'Traveler' }}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="tab-content p-0" id="pills-tabContent">
      <div
        *ngIf="queryFlightParams.tripType === 'Round'"
        aria-labelledby="pills-round-trip-tab"
        class="tab-pane fade show active"
        id="pills-round-trip"
        role="tabpanel">
        <form class="" [formGroup]="roundTripForm">
          <div class="input-daterange mb-0 input-group" id="flights">
            <div class="form-floating col-6 px-0">
              <input
                [(ngModel)]="flight.from"
                [ngClass]="{
                  'has-error': !searchFormValid && !roundTripForm.get('flyingFrom')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                type="text"
                name="from-rt"
                class="form-control"
                id="flyingFrom"
                placeholder="Flying from?"
                aria-required="true"
                aria-invalid="false"
                appClearIcon
                autocomplete="off"
                [matAutocomplete]="autoFrom"
                formControlName="flyingFrom"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoFrom="matAutocomplete"
                (optionSelected)="jumpToNextControl(flyingTo)">
                <mat-option *ngFor="let city of filteredFromData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
              <label for="flyingFrom" class="icon-departure">Flying from?</label>
            </div>
            <div class="form-floating col-6 px-0">
              <input
                id="flyingToRound"
                [ngClass]="{
                  'has-error': !searchFormValid && !roundTripForm.get('flyingTo')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingTo
                type="text"
                name="to-rt"
                class="form-control"
                placeholder="Flying to?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                [matAutocomplete]="autoTo"
                formControlName="flyingTo"
                [(ngModel)]="flight.to"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoTo="matAutocomplete"
                (optionSelected)="jumpToNextControl(dpDepart, 'flyingToRound')">
                <mat-option *ngFor="let city of filteredToData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
              <label for="flyingToRound" class="icon-arrival">Flying to?</label>
            </div>
          </div>

          <div class="input-daterange mb-0 input-group" id="flight-datepicker">
            <div
              (click)="activeDepart = true"
              (appClickOutside)="onBlur('roundTripDepart')"
              class="form-floating col-6 px-0 form-item">
              <input
                id="roundTripDepart"
                type="text"
                name="depart-rt"
                class="form-control datepicker-label"
                placeholder="Depart"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                [ngClass]="{
                  'active-datepicker': activeDepart,
                  'has-error': !searchFormValid && !roundTripForm.get('departDate')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpDepart="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'roundTripDepart')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpDepart.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateDepart"
                required
                formControlName="departDate"
                restoreFocus="false"
                [readonly]="true"
                [(ngModel)]="flight.departDate"
                (ngModelChange)="initReturnDatepicker(); jumpToNextControl(dpReturn)" />
              <label
                [class.move-label]="dpDepart.isOpen()"
                for="roundTripDepart"
                class="icon-depart"
                >Depart</label
              >
            </div>
            <div
              class="form-floating col-6 px-0 form-item"
              (click)="activeReturn = true"
              (appClickOutside)="onBlur('roundTripReturn')">
              <input
                id="roundTripReturn"
                type="text"
                name="return-rt"
                class="form-control datepicker-label"
                placeholder="Return"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                [ngClass]="{
                  'active-datepicker': activeReturn,
                  'has-error': !searchFormValid && !roundTripForm.get('returnDate')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpReturn="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpReturn, 'roundTripReturn')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpReturn.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateReturn"
                [(ngModel)]="returnDate"
                required
                formControlName="returnDate"
                [readonly]="true"
                restoreFocus="false" />
              <label
                [class.move-label]="dpReturn.isOpen()"
                for="roundTripReturn"
                class="icon-return"
                >Return</label
              >
            </div>
          </div>
        </form>
      </div>

      <div
        *ngIf="queryFlightParams.tripType === 'OneWay'"
        aria-labelledby="pills-one-way-tab"
        class="tab-pane fade show active"
        id="pills-one-way"
        role="tabpanel">
        <form class="" [formGroup]="oneWayForm">
          <div class="input-daterange mb-0 input-group" id="flights">
            <div class="form-floating col-6 px-0">
              <input
                [ngClass]="{
                  'has-error': !searchFormValid && !oneWayForm.get('flyingFrom')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                type="text"
                name="from-rt"
                id="flyingFromOne"
                class="form-control"
                placeholder="Flying from?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                [(ngModel)]="flight.from"
                required
                formControlName="flyingFrom"
                [matAutocomplete]="autoFrom"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoFrom="matAutocomplete"
                (optionSelected)="jumpToNextControl(flyingToOneWay)">
                <mat-option *ngFor="let city of filteredFromOneWayData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
              <label class="icon-departure" for="flyingFromOne">Flying from?</label>
            </div>
            <div class="form-floating col-6 px-0">
              <input
                id="flyingToOneWay"
                [ngClass]="{
                  'has-error': !searchFormValid && !oneWayForm.get('flyingTo')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingToOneWay
                type="text"
                name="to-rt"
                class="form-control"
                placeholder="Flying to?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                [(ngModel)]="flight.to"
                required
                [matAutocomplete]="autoTo"
                formControlName="flyingTo"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoTo="matAutocomplete"
                (optionSelected)="jumpToNextControl(dpDepart, 'flyingToOneWay')">
                <mat-option *ngFor="let city of filteredToOneWayData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
              <label class="icon-arrival" for="flyingToOneWay">Flying to?</label>
            </div>
          </div>

          <div class="input-daterange mb-0 input-group" id="flight-datepicker">
            <div
              class="form-floating col-12 px-0 form-item"
              (click)="activeDepart = true"
              (appClickOutside)="onBlur('oneWayDepart')">
              <input
                id="oneWayDepart"
                type="text"
                name="depart-rt"
                class="form-control datepicker-label"
                placeholder="Depart"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                [ngClass]="{
                  'active-datepicker': activeDepart,
                  'has-error': !searchFormValid && !oneWayForm.get('departDate')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpDepart="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'oneWayDepart')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpDepart.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateDepart"
                [(ngModel)]="flight.departDate"
                required
                formControlName="departDate"
                [readonly]="true"
                restoreFocus="false" />
              <label [class.move-label]="dpDepart.isOpen()" class="icon-depart" for="oneWayDepart"
                >Depart</label
              >
            </div>
          </div>
        </form>
      </div>

      <div
        *ngIf="queryFlightParams.tripType === 'MultiCity'"
        aria-labelledby="pills-multi-city-tab"
        class="tab-pane fade show active"
        id="pills-multi-city"
        role="tabpanel">
        <form class="" [formGroup]="multiCityForm">
          <div class="input-daterange mb-0 input-group" id="flights">
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingFrom')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                type="text"
                name="from-rt"
                id="flyingFromMulti"
                class="form-control"
                placeholder="Flying from?"
                aria-required="true"
                aria-invalid="false"
                appClearIcon
                autocomplete="off"
                [(ngModel)]="flight.from"
                required
                formControlName="flyingFrom"
                [matAutocomplete]="autoFrom"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoFrom="matAutocomplete"
                (optionSelected)="jumpToNextControl(flyingToMulty)">
                <mat-option *ngFor="let city of filteredFromMultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
              <label class="icon-departure" for="flyingFromMulti">Flying from?</label>
            </div>
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                id="flyingToMultiCity"
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingTo')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingToMulty
                type="text"
                name="to-rt"
                class="form-control"
                placeholder="Flying to?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                [(ngModel)]="flight.to"
                required
                [matAutocomplete]="autoTo"
                formControlName="flyingTo"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoTo="matAutocomplete"
                (optionSelected)="jumpToNextControl(dpDepart, 'flyingToMultiCity')">
                <mat-option *ngFor="let city of filteredToMultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
              <label class="icon-arrival" for="flyingToMultiCity">Flying to?</label>
            </div>
            <div
              class="form-floating col-12 col-xl-4 px-0 form-item"
              id="flight-datepicker"
              (click)="activeDepart = true"
              (appClickOutside)="onBlur('multiCityDepart')">
              <input
                id="multiCityDepart"
                type="text"
                name="depart-rt"
                class="input-sm form-control datepicker-label"
                placeholder="Depart"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                restoreFocus="false"
                [ngClass]="{
                  'active-datepicker': activeDepart,
                  'has-error': !searchFormValid && !multiCityForm.get('departDate')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpDepart="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'multiCityDepart')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpDepart.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateDepart"
                formControlName="departDate"
                [(ngModel)]="flight.departDate"
                [readonly]="true"
                (ngModelChange)="initDepartDatepicker(); jumpToNextControl(flyingFromMulty1)" />
              <label
                [class.move-label]="dpDepart.isOpen()"
                class="icon-depart"
                for="multiCityDepart"
                >Depart</label
              >
            </div>
          </div>

          <div class="input-daterange mb-0 input-group" id="flights">
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingFrom1')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingFromMulty1
                type="text"
                name="from-rt"
                id="flyingFromMulti1"
                class="form-control"
                placeholder="Flying from?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                formControlName="flyingFrom1"
                [(ngModel)]="flight1.from"
                [matAutocomplete]="autoFrom1"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoFrom1="matAutocomplete"
                (optionSelected)="jumpToNextControl(flyingToMulty1)">
                <mat-option *ngFor="let city of filteredFrom1MultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight1.from?.length"></span>
              <label class="icon-departure" for="flyingFromMulti1">Flying from?</label>
            </div>
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                id="flyingTo1MultiCity"
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingTo1')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingToMulty1
                type="text"
                name="to-rt"
                class="form-control"
                placeholder="Flying to?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                formControlName="flyingTo1"
                [(ngModel)]="flight1.to"
                [matAutocomplete]="autoTo1"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoTo1="matAutocomplete"
                (optionSelected)="jumpToNextControl(dpDepart1, 'flyingTo1MultiCity')">
                <mat-option *ngFor="let city of filteredTo1MultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight1.to?.length"></span>
              <label class="icon-arrival" for="flyingTo1MultiCity">Flying to?</label>
            </div>
            <div
              class="form-floating col-12 col-xl-4 px-0 form-item"
              id="flight-datepicker"
              (click)="activeDepart1 = true"
              (appClickOutside)="onBlur('multiCityDepart1')">
              <input
                id="multiCityDepart1"
                type="text"
                name="depart-rt"
                class="input-sm form-control datepicker-label"
                placeholder="Depart"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                restoreFocus="false"
                [ngClass]="{
                  'active-datepicker': activeDepart1,
                  'has-error': !searchFormValid && !multiCityForm.get('departDate1')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpDepart1="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpDepart1, 'multiCityDepart1')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpDepart1.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateDepart1"
                formControlName="departDate1"
                [(ngModel)]="flight1.departDate"
                [readonly]="true"
                (ngModelChange)="initDepartDatepicker(); jumpToNextControl(flyingFromMulty2)" />
              <label
                [class.move-label]="dpDepart1.isOpen()"
                class="icon-depart"
                for="multiCityDepart1"
                >Depart</label
              >
            </div>
          </div>

          <div [hidden]="!showThirdFlight" class="input-daterange mb-0 input-group" id="flights">
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingFrom2')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingFromMulty2
                type="text"
                name="from-rt"
                id="flyingFromMulti2"
                class="form-control"
                placeholder="Flying from?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                formControlName="flyingFrom2"
                [(ngModel)]="flight2.from"
                [matAutocomplete]="autoFrom2"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoFrom2="matAutocomplete"
                (optionSelected)="jumpToNextControl(flyingToMulty2)">
                <mat-option *ngFor="let city of filteredFrom2MultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight2.from?.length"></span>
              <label for="flyingFromMulti2" class="icon-departure">Flying from?</label>
            </div>
            <div class="form-floating col-6 col-xl-4 px-0">
              <input
                id="flyingTo2MultiCity"
                [ngClass]="{
                  'has-error': !searchFormValid && !multiCityForm.get('flyingTo2')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                #flyingToMulty2
                type="text"
                name="to-rt"
                class="form-control"
                placeholder="Flying to?"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                appClearIcon
                formControlName="flyingTo2"
                [(ngModel)]="flight2.to"
                [matAutocomplete]="autoTo2"
                (click)="makeAny($event.target).select()"
                (focus)="changeMustSubmit(false)"
                (focusout)="onFocusOutAirportInput($event)" />
              <mat-autocomplete
                #autoTo2="matAutocomplete"
                (optionSelected)="jumpToNextControl(dpDepart2, 'flyingTo2MultiCity')">
                <mat-option *ngFor="let city of filteredTo2MultiCityData | async" [value]="city">
                  <span>{{ city }}</span>
                </mat-option>
              </mat-autocomplete>
              <span class="xIcon" [class.xIcon-active]="flight2.to?.length"></span>
              <label for="flyingTo2MultiCity" class="icon-arrival">Flying to?</label>
            </div>
            <div
              class="form-floating col-12 col-xl-4 px-0 form-item"
              id="flight-datepicker"
              (click)="activeDepart2 = true"
              (appClickOutside)="activeDepart2 = false; onBlur('multiCityDepart2')">
              <input
                id="multiCityDepart2"
                type="text"
                name="depart-rt"
                class="form-control datepicker-label"
                placeholder="Depart"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                restoreFocus="false"
                [ngClass]="{
                  'active-datepicker': activeDepart2,
                  'has-error': !searchFormValid && !multiCityForm.get('departDate2')?.valid,
                }"
                [attr.disabled]="formSending ? '' : null"
                ngbDatepicker
                #dpDepart2="ngbDatepicker"
                (appClickOutside)="onClickedOutsideDp($event, dpDepart2, 'multiCityDepart2')"
                [navigation]="navigation"
                [firstDayOfWeek]="firstDayOfWeek"
                [displayMonths]="displayMonths"
                (click)="dpDepart2.toggle(); changeMustSubmit(true)"
                [maxDate]="maxDate"
                [minDate]="minDateDepart2"
                formControlName="departDate2"
                [(ngModel)]="flight2.departDate"
                [readonly]="true" />
              <label
                [class.move-label]="dpDepart2.isOpen()"
                for="multiCityDepart2"
                class="icon-depart"
                >Depart</label
              >
            </div>
          </div>

          <button
            *ngIf="showAddFlightButton"
            (click)="addFlight()"
            class="w-100 btn btn-lg btn-add"
            type="submit">
            Add Flight +
          </button>
        </form>
      </div>

      <form class="px-0" [formGroup]="queryPanelForm">
        <div class="input-daterange mb-0 input-group" id="flights">
          <div class="form-floating col-6 px-0">
            <div class="form-floating mb-0">
              <input
                [ngClass]="{
                  'has-error': !queryPanelForm.get('firstName')?.valid && !queryPanelValid,
                }"
                [attr.disabled]="formSending ? '' : null"
                type="text"
                name="reqName"
                id="name"
                class="form-control"
                placeholder="Name"
                maxlength="150"
                aria-required="true"
                aria-invalid="false"
                autocomplete="off"
                formControlName="firstName"
                [(ngModel)]="contactData.firstName"
                (click)="makeAny($event.target).select()"
                appClearIcon />
              <span class="xIcon" [class.xIcon-active]="contactData.firstName?.length"></span>
              <label class="icon-name" for="name">Name</label>
            </div>
          </div>
          <div class="form-floating col-6 px-0">
            <div class="form-floating mb-0">
              <input
                [ngClass]="{
                  'has-error': !queryPanelForm.get('phone')?.valid && !queryPanelValid,
                }"
                [attr.disabled]="formSending ? '' : null"
                type="tel"
                id="contactField"
                class="form-control"
                formControlName="phone"
                placeholder="Phone"
                [(ngModel)]="contactPhoneModel"
                #phoneSelect="ngx-phone-select"
                ngxPhoneSelect
                appClearIcon
                (click)="makeAny($event.target).select()"
                [textMask]="{ mask: phoneMask }"
                (countryChangeEvent)="countryChanged()" />
              <span class="xIcon" [class.xIcon-active]="contactPhoneModel?.length"></span>
              <label [class.iti-active]="formSending" for="contactField">Phоne</label>
            </div>
          </div>
        </div>

        <div class="form-floating mb-0">
          <input
            [ngClass]="{
              'has-error': !queryPanelForm.get('email')?.valid && !queryPanelValid,
            }"
            [attr.disabled]="formSending ? '' : null"
            type="email"
            id="email"
            name="reqEmail"
            class="form-control"
            placeholder="Email"
            maxlength="150"
            aria-required="true"
            aria-invalid="false"
            autocomplete="off"
            formControlName="email"
            [(ngModel)]="contactData.email"
            (click)="makeAny($event.target).select()"
            appClearIcon />
          <span class="xIcon" [class.xIcon-active]="contactData.email?.length"></span>
          <label class="icon-email" for="email">Email</label>
        </div>

        <div *ngIf="normalFlight" class="p-3 btn-bg">
          <button
            class="w-100 btn btn-lg btn-primary"
            type="submit"
            (click)="getQuote()"
            [attr.disabled]="formSending ? '' : null">
            Send Me Quotes
          </button>
        </div>

        <div *ngIf="!normalFlight">
          <div id="errorBox" class="col-xs-12 pn errorBox container">
            <div class="alert" role="alert">
              We don’t offer flights to this destination or economy tickets.
            </div>
          </div>
        </div>
      </form>
    </div>
  </form>
</ng-template>
