import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ClearIconDirective } from '../app/directives/clear-icon.directive';
import { ClickOutsideDirective } from '../app/directives/click-outside.directive';
import { NgxPhoneSelectModule } from '../app/directives/ngx-phone-select/ngx-phone-select.module';
import { CallMeDialogComponent } from '../app/layout/modal/call-me-dialog/call-me-dialog.component';
import { ContactUsDialogComponent } from '../app/layout/modal/contact-us-dialog/contact-us-dialog.component';
import { GetQuoteDialogComponent } from '../app/layout/modal/get-quote-dialog/get-quote-dialog.component';
import { NewsLetterSuccessDialogComponent } from '../app/layout/modal/news-letter-success-dialog/news-letter-success-dialog.component';
import { BookWithUsComponent } from '../app/layout/templates/book-with-us/book-with-us.component';
import { CityDealComponent } from '../app/layout/templates/city-deal/city-deal.component';
import { CountryDealComponent } from '../app/layout/templates/country-deal/country-deal.component';
import { FreeQuoteComponent } from '../app/layout/templates/free-quote/free-quote.component';
import { FrequentlyAskedQuestionsComponent } from '../app/layout/templates/frequently-asked-questions/frequently-asked-questions.component';
import { HowItWorksComponent } from '../app/layout/templates/how-it-works/how-it-works.component';
import { NewsLetterComponent } from '../app/layout/templates/news-letter/news-letter.component';
import { OfferComponent } from '../app/layout/templates/offer/offer.component';
import { PricingHowItWorksComponent } from '../app/layout/templates/pricing-how-it-works/pricing-how-it-works.component';
import { SearchboxComponent } from '../app/layout/templates/searchbox/searchbox.component';
import { ServicesComponent } from '../app/layout/templates/services/services.component';
import { TestimonialsComponent } from '../app/layout/templates/testimonials/testimonials.component';
import { PhonePipe } from '../app/pipes/phone.pipe';
import { SafeHtmlPipe } from '../app/pipes/safe-html-pipe';
import { SafeUrlPipe } from '../app/pipes/safe-url.pipe';
import {RECAPTCHA_LANGUAGE, RecaptchaModule} from "ng-recaptcha";

const components: Array<Type<any> | any[]> = [
  SafeUrlPipe,
  SafeHtmlPipe,
  PhonePipe,
  ClickOutsideDirective,
  ClearIconDirective,
  SearchboxComponent,
  OfferComponent,
  FreeQuoteComponent,
  BookWithUsComponent,
  TestimonialsComponent,
  FrequentlyAskedQuestionsComponent,
  CallMeDialogComponent,
  GetQuoteDialogComponent,
  ContactUsDialogComponent,
  HowItWorksComponent,
  NewsLetterComponent,
  NewsLetterSuccessDialogComponent,
  CountryDealComponent,
  PricingHowItWorksComponent,
  CityDealComponent,
  ServicesComponent,
];

const modules: Array<Type<any> | any[]> = [
  CommonModule,
  RouterModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbAccordionModule,
  NgbCarouselModule,
  LazyLoadImageModule,
  ReactiveFormsModule,
  TextMaskModule,
  MatAutocompleteModule,
  MatOptionModule,
  MatInputModule,
  NgbDatepickerModule,
  MatDatepickerModule,
  NgxPhoneSelectModule,
  MatDialogModule,
  NgbToastModule,
  RecaptchaModule
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...modules, ...components],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en',
    },
  ],
})
export class SharedModule {}
