import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';

import {environment} from '../../../../environments/environment';
import {phoneMask, phonePattern} from '../../../app.constants';
import {ContactData} from '../../../model/contact-data';
import {CallMeService} from '../../../services/call-me.service';
import {DataService} from '../../../services/shared-data.service';
import {ToastService} from '../../../services/toast.service';
import {RecaptchaComponent} from 'ng-recaptcha';

@Component({
    selector: 'app-call-me-dialog',
    templateUrl: './call-me-dialog.component.html',
    styleUrls: ['./call-me-dialog.component.scss'],
    providers: [CallMeService],
})
export class CallMeDialogComponent implements OnInit, OnDestroy {
    @ViewChild('recaptchaElement', {static: false}) recaptchaElement: RecaptchaComponent;

    public callMeForm: FormGroup;
    public callMeData: ContactData = new ContactData();
    public readonly phoneMask: (string | RegExp)[] = phoneMask;
    public cdnPath: string;
    public isRecaptchaResolved: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private callMeService: CallMeService,
        private router: Router,
        private dataService: DataService,
        private toastService: ToastService,
    ) {
        this.cdnPath = environment.cdnPath;
    }

    public ngOnInit(): void {
        this.initializeForm();
    }

    public initializeForm(): void {
        this.callMeForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            phone: ['', Validators.compose([Validators.required, Validators.pattern(phonePattern)])],
            // email: ['', Validators.compose([Validators.required, Validators.email])]
        });
    }

    public onCloseDialog(): void {
        this.activeModal.close();
    }

    public callMe(): void {
        if (this.callMeForm.valid && this.isRecaptchaResolved === true) {
            this.callMeService.callMe(this.callMeData).subscribe(response => {
                if (_.eq(response.status, 'FAILED')) {
                    this.toastService.showWarning("Can't process your request now. Please try later.", 10000);
                } else {
                    this.onCloseDialog();
                    this.router.navigateByUrl('/thanks');
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.dataService.sendData({type: 'showModal', data: false});
    }

    public resolved(captchaResponse: string): void {
        this.isRecaptchaResolved = !!captchaResponse;
    }
}
